import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "components/seo"
import Link from 'components/link'
import FlexibleContent from "../components/flexibleContent"
import HeroPlace from "../components/heroPlace"

export default ({ data, location }) => {

    const [hoursOpen, setHoursOpen] = useState(false)

    const toggleHours = () => {
        if (hoursOpen) {
            setHoursOpen(false)
        } else {
            setHoursOpen(true)
        }
    }

    const {
        databaseId,
        title,
        content,
        flexibleContent,
        seoFields
    } = data.siteFields.page

    let excerpt = data.content ? data.content.replace(/(<([^>]+)>)/ig, "") : ''
    excerpt = excerpt ? excerpt.slice(0, 160) : ''
    if (excerpt.length === 160) {
        excerpt = excerpt + '...'
    }

    const {
        directoryFields: {
            backgroundImage,
            logo,
            openingHours,
            openingHoursAdditionalTitle,
            openingHoursAdditional,
            logoSizeInHero,
            location: _location,
            phoneNumber,
            website,
            emailAddress,
            instagram,
            facebook
        }
    } = data.siteFields.place

    const _backgroundImage = backgroundImage ? backgroundImage.mediaItemUrl : ''
    const _logo = logo ? logo.mediaItemUrl : ''

    return (
        <Layout location={location}>
            <SEO
                title={title}
                description={excerpt}
                seoFields={seoFields}
            />
            <HeroPlace
                title={title}
                backgroundImage={_backgroundImage}
                logo={_logo}
                logoSize={logoSizeInHero}
            />
            <div className="place">
                <div className="container">
                    <div className="place-content">
                        <Link to='/directory' className="button back">
                            Back to all
                        </Link>
                        <h1 dangerouslySetInnerHTML={{ __html: title }} />
                        <div dangerouslySetInnerHTML={{ __html: content }} className="content" />
                    </div>
                    <div className="place-details">
                        <div className="place-details-feature-cont">
                            <div className="place-details-feature">
                                <div>
                                    {_location ?
                                        <div className="place-details-contact">
                                            <h3>
                                                Location
                                            </h3>
                                            <div dangerouslySetInnerHTML={{ __html: _location }} />
                                        </div>
                                        : ''}
                                    {phoneNumber || website ?
                                        <div className="place-details-contact">
                                            <h3 dangerouslySetInnerHTML={{ __html: 'Contact Details' }} />
                                            {phoneNumber && 
                                                <a 
                                                    href={'tel:' + phoneNumber} 
                                                    dangerouslySetInnerHTML={{ __html: phoneNumber }} 
                                                />
                                            }
                                            {website && 
                                                <a 
                                                    href={website.url} 
                                                    target="_blank" 
                                                    rel="noopener noreferrer" 
                                                    dangerouslySetInnerHTML={{ __html: website.title }} 
                                                />
                                            }
                                            {emailAddress && 
                                                <a 
                                                    href={'mailto:' + emailAddress} 
                                                    dangerouslySetInnerHTML={{ __html: 'Email us' }} 
                                                />
                                            }
                                        </div>
                                        : ''}
                                </div>
                            </div>
                            <svg width="0" height="0">
                                <defs>
                                    <clipPath id="clipPath-arch1" clipPathUnits="objectBoundingBox" transform="scale(0.002, 0.002)">
                                        <path d="M500,550V250.33c0-333.77-500-333.77-500,0V550Z" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                        {openingHours ?
                            <div
                                className={`place-details-hours ${hoursOpen ? 'open' : ''}`}
                                onClick={toggleHours}
                                role="button"
                                tabIndex={0}
                            >
                                <h3 dangerouslySetInnerHTML={{ __html: 'Opening Hours' }} />
                                {openingHours.map((row, index) => {
                                    const date = new Date()
                                    const weekday = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
                                    const dayOfWeek = weekday[date.getDay()]

                                    return (
                                        <div key={index} className={row.day === dayOfWeek ? 'selected' : ''}>
                                            <span dangerouslySetInnerHTML={{ __html: row.day }} />
                                            <div dangerouslySetInnerHTML={{ __html: row.hours }} />
                                        </div>
                                    )
                                })
                                }
                            </div>
                        : ''}
                        {openingHoursAdditional ?
                            <div className={`place-details-hours-additional`}>
                                <h3 dangerouslySetInnerHTML={{ __html: openingHoursAdditionalTitle }} />
                                {openingHoursAdditional.map((row, index) => {
                                    return (
                                        <div key={index}>
                                            <span dangerouslySetInnerHTML={{ __html: row.title }} />
                                            <div dangerouslySetInnerHTML={{ __html: row.hours }} />
                                        </div>
                                    )
                                })
                                }
                            </div>
                        : ''}
                        {instagram && facebook ?
                            <div className="place-details-social">
                                <p dangerouslySetInnerHTML={{ __html: 'Follow us on' }} />
                                {instagram && 
                                    <a href={instagram} target="_blank" rel="noopener noreferrer">
                                        <i className={'fe fe-instagram'} />
                                        Instagram
                                    </a>
                                }
                                {instagram && facebook ? ' and ' : ''}
                                {facebook && 
                                    <a href={facebook} target="_blank" rel="noopener noreferrer">
                                        <i className={'fe fe-facebook'} />
                                        Facebook
                                    </a>        
                                }
                            </div>
                            : ''}
                    </div>
                </div>
            </div>
            <div className="place-flexibleContent">
                <FlexibleContent id={databaseId} content={flexibleContent} />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($databaseId: ID!) {
        siteFields {
            page(id: $databaseId, idType: DATABASE_ID) {
                databaseId
                title
                date
				seoFields {
					metaTitle
					metaDescription
					ogpImage {
						mediaItemUrl
					}
				}
                content(format: RENDERED)
                featuredImage {
                    node {
                        altText
                        title(format: RENDERED)
                        mediaItemUrl
                        slug
                    }
                }
                flexibleContent {
                    ... FlexibleContentFragment
                }
            }
            place(id: $databaseId, idType: DATABASE_ID) {
                title
                slug
                content(format: RENDERED)
                directoryFields {
                    logoSizeInHero
                    logo {
                        mediaItemUrl
                    }
                    openingHours {
                        day
                        hours
                    }
                    openingHoursAdditionalTitle
                    openingHoursAdditional {
                        title
                        hours
                    }
                    backgroundImage {
                        mediaItemUrl
                    }
                    phoneNumber
                    location
                    website {
                        title
                        url
                    }
                    emailAddress
                    instagram
                    facebook
                }
            }
        }
    }
`
