import React, { useContext, useEffect, useRef } from "react"
import { MainContext } from "context/context"

export default ({ title, backgroundImage, logo, logoSize }) => {

	const {
        addInvertedHeaderSection
    } = useContext(MainContext)

    const elementRef = useRef(null)
    
    useEffect(() => {
        if(elementRef && elementRef.current){
            addInvertedHeaderSection(elementRef.current)
        }
    })

    return (

        <div className='Hero-cont Hero-place hasBG blue bg' style={{ backgroundImage: `url(${backgroundImage})` }} ref={ref => elementRef.current = ref}>

            { logo ? <div className={ "Hero-logo " + logoSize } style={{ backgroundImage: `url(${logo})` }} /> : <h1 dangerouslySetInnerHTML={{ __html: title }} /> }

        </div>
        
    )
}
